.form-app {
  padding: 0px 24px 49px 20px;
  background: #f7f7f7;
  border: 1px solid $light;
  box-sizing: border-box;
  border-radius: 6px;
  .form-group {
    width: 100%;
    float: left;
    margin-bottom: 0;
    margin-top: 30px;
    font-size: 17px;
  }
  .form-control {
    padding: 5px;
    border: 1px solid $light;
    height: 60px;
    background: transparent;
    font-size: 17px;
  }
}

.custom-switch-control {
  margin-left: 10px;
  position: relative;
  .custom-control-label::before {
    width: 50px;
    height: 20px;
  }
  .custom-control-label::after {
    width: 20px;
    height: 16px;
    left: calc(-2.25rem + 1px);
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(1.7rem);
  }
}
