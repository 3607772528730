// Core

body {
  margin: 0 auto;
  padding: 0;
  font-family: "Circe";
  background: #f7f7f7;
}

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/CRC55.woff") format("woff");
}

@import "custom/variables";
@import "custom/icon";
@import "custom/typograpy";

@import "custom/navbar";

@import "bootstrap/scss/bootstrap";

@import "custom/button";
@import "custom/table";
@import "custom/form";
// regular style toast
@import "ngx-toastr/toastr";

.modal {
  z-index: 10000;
}