$primary: #e16301;
$input-btn-font-size: 14px;
$secondary: #717b86;
$font-family-base: "Circe" !default;
$dark-orange: #9d4117;
$dark-grey: #717b86;
$light: #aeadad;
$black: #060b23;
$white: #fff;
$button-border-radius: 6px;
$input-btn-font-size-sm: 0.75rem !default;
$input-btn-font-size: 0.875rem !default;
$input-btn-font-size-lg: 0.875rem !default;
$input-border-radius: 6px;
$input-border-color: #717b86;
$input-border-radius-lg: 6px;
$input-line-height-lg: 3;
$hr-border-color: #aeadad;
$dropdown-link-color: $dark-grey;
$table-border-color: #aeadad;
$light-grey: lightgray;

a {
  color: $dark-orange;
}

$colors: () !default;
$colors: map-merge(
  (
    "dark-orange": $dark-orange,
    "black": $black,
  ),
  $colors
);

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
}

@mixin width-classes {
  @for $i from 1 through 1000 {
    $width: $i * 1px;

    .width-#{$i} {
      width: $width;
    }
  }
}

@mixin font-size-classes {
  @for $i from 1 through 1000 {
    $size: $i * 1px;

    .font-size-#{$i} {
      font-size: $size;
    }
  }
}

@include width-classes();
@include font-size-classes();
