.table-zeus {
  border-collapse: separate;
  border-spacing: 0;

  th {
    &:first-child {
      border-top-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
    }
  }

  tr {
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  th[sortable] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
  }

  th[sortable]:before {
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADPSURBVHgBzdJJDoIwFAbg98SAkaUXwGnvEfAGHsUboCfQI7H0EmpPQNgYESlPIBSTSgdd+SdNh7RfOgFo4o5nkevPrzAKAvglLUBt0UKoAgBxJw2znPM1ZIwZEQWghfALQAl1iOcvtgR0ALuw/Haeis5QNErAGKnc2whEkMJf5n2x/nLlEt9YLaqO87hfjqLf3YkHFBJiZIMQAquqDnFEgz+Tk+NN6p2FBoMNqicuijT9QBooT2ID1ACZ9NkceZYG6gV6EQWkBJSIBAU6oM4LcNZvPmOmF8QAAAAASUVORK5CYII=)
      no-repeat;
    background-size: 18px;
    width: 20px;
    height: 20px;
    float: right;
  }

  th[sortable].desc:before,
  th[sortable].asc:before {
    content: "";
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgB5cyxDYMwEIXhhy9xETdRJohkpSebZISswESMgJiAFWADNoAaCxsjRIPAGJfwN6c7nT7gQolPfBfyj+AmoOFCGjtT1yttAtBFBDzH1c6Y8ddbqyb3QxbAnAsiH2APIl/ABdERYAuio8AaFPGHzOzlh8BM139vTPeJZqxCQMagVaouca4Gomk5C2dzzVYAAAAASUVORK5CYII=)
      no-repeat;
    background-size: 18px;
    width: 20px;
    height: 20px;
    float: right;
  }

  th[sortable].desc:before {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    margin-top: 0px;
  }

  td,
  th {
    white-space: nowrap;
  }
}
