//
// Icon buttons
//

// General styles

.btn {
  position: relative;
  font-size: $input-btn-font-size;

  &:hover {
    //  @include box-shadow($btn-hover-box-shadow);
    //  transform: translateY($btn-hover-translate-y);
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: 0.5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.border-light {
  color: $light;
}
.text {
  color: $black;
}
.btn-outline-primary {
  color: $secondary;
  border-radius: $button-border-radius;
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}

// Size variations

.btn-sm {
  font-size: $input-btn-font-size-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
}

// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-inner--icon {
  i:not(.fa) {
    position: relative;
    top: 2px;
  }
}

.btn-primary-shadow {
  background: linear-gradient(180deg, #fd7f00 0%, $primary 100%);
  box-shadow: 0px 8px 8px rgba(251, 125, 1, 0.15);
  color: #f7f7f7;
  font-size: 18px;
  &:hover {
    color: #f7f7f7;
  }
}
